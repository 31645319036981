.page-footer {
  padding: 60px 0 45px;

  color: #fff;

  background-color: #1C202B;
}

.page-footer__container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.page-footer__middle-block {
  margin: 0 auto;

  font-size: 15px;
}

.page-footer__link {
  margin-right: 45px;
  
  color: #fff;
  text-decoration: none;
}

@media (max-width: $tablet-width-only) {
  .page-footer__container {
    flex-direction: column;
  }

  .page-footer__networks {
    order: -2;
    margin: 0 auto
  }

  .page-footer__middle-block {
    order: -1;
    margin: 15px auto;
  }

  .page-footer__language-toggler {
    margin: 0 auto;
  }
}
