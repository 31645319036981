.section-head {
  margin: 0 0 30px;

  font-size: 36px;
  color: $third-color;
  text-align: center;
  font-weight: 900;
}

@media (max-width: $tablet-width-only) {
  .section-head {
    font-size: 28px;
  }
}

@media (max-width: $tablet-width-only) {
  .section-head {
    font-size: 25px;
  }
}
