.reasons-to-choose {
  padding: 50px 0 70px;
}

.reasons-to-choose__list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 375px);
  grid-row-gap: 50px;
  justify-content: space-between;

  list-style: none;
}

.reasons-to-choose__img {
  margin-bottom: 25px;
}

.reasons-to-choose__desc {
  margin: 0;

  font-size: 16px;
}

@media (max-width: $tablet-width-only) {
  .reasons-to-choose__list {
    grid-template-columns: 375px 375px;
    justify-content: space-evenly;
  }
}

@media (max-width: 860px) {
  .reasons-to-choose__list {
    grid-template-columns: minmax(290px, 375px);
  }
}

@media (max-width: $mobile-width-only) {
  .reasons-to-choose {
    padding: 50px 0 50px;
  }
}
