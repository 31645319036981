.section-posthead {
  width: 700px;
  margin: 0 auto 55px;

  text-align: center;
}

@media (max-width: $tablet-width-only) {
  .section-posthead {
    width: 100%;
  }
}

@media (max-width: $mobile-width-only) {
  
}
