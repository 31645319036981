.our-contacts__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.our-contacts__text-block {
  width: 350px;
}

.our-contacts__section-head {
  margin: 0 0 40px;

  text-align: left;
  font-size: 40px;
  font-weight: 700;
}

.our-contacts__subhead {
  margin: 0 0 25px;

  font-size: 20px;
  line-height: 29px;
  color: $third-color;
}

.our-contacts__address {
  margin: 0 0 25px;

  line-height: 29px;
}

.our-contacts__contact {
  position: relative;

  padding-left: 50px;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 15px;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
  }
}

.our-contacts__contact--phone::before {
  width: 13px;
  height: 20px;

  background-image: url("../img/cell-phone.svg");
}

.our-contacts__contact--email::before {
  width: 21px;
  height: 16px;

  background-image: url("../img/email.svg");
}

.our-contacts__map-container {
  position: relative;
}

.our-contacts__map-iframe {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: $tablet-width-only) {
  .our-contacts {
    padding-bottom: 120px;
  }

  .our-contacts__container {
    flex-direction: column;
    align-items: center;
  }

  .our-contacts__section-head {
    text-align: center;
  }

  .our-contacts__text-block {
    margin-bottom: 20px;
  }

  .our-contacts__map-container {
    position: relative;

    max-width: 708px;
    max-height: 426px;
    width: 100%;
    height: 100%;
  }

  .our-contacts__map-img {
    width: 708px;
    height: 426px;
  }

  .our-contacts__map-iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 708px;
    height: 426px;
  }
}

@media (max-width: $mobile-width-only) {
  .our-contacts {
    padding-bottom: 50px;
  }

  .our-contacts__map-img {
    width: 100%;
    height: auto;
  }

  .our-contacts__map-iframe {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 450px) {
  .our-contacts__map-container, .our-contacts__container {
    overflow: hidden;
  }
}
