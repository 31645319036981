.technologies {
  padding: 50px 0 70px;
}

.technologies__container{
  overflow: hidden;
}

.technologies__top-block {
  width: 670px;
}

.technologies__section-head {
  margin: 0 0 50px;

  text-align: left;
}

.technologies__headers {
  position: relative;

  display: inline-flex;
  margin: 0 0 30px;
  padding: 0;

  list-style: none;
  z-index: 1;

  &::after {
    content: '';

    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;

    width: 100%;
    height: 3px;

    background-color: #F0F4F8;
  }
}

.technologies__header {
  position: relative;
  z-index: 2;

  margin-right: 50px;

  font-family: $second-font;
  font-weight: 600;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: $third-color;

    &:hover,
    &:focus {
      color: $second-color;
    }
  }
}

.technologies__header.active {
  padding-bottom: 9px;


  border-bottom: 3px solid $second-color;

  a {
    color: $second-color;
  }
}

.technologies__desc {
  max-width: 700px;
  margin: 0 0 50px;
}

.technologies__list {
  position: relative;

  margin: 0;
  padding: 0;

  display: flex;

  list-style: none;

  .owl-nav button {
    position: absolute;
    top: calc(50% - 15.28px);

    width: 17.44px;
    height: 30.56px;

    font-size: 0 !important;

    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .owl-prev {
    left: 0;

    background-image: url("../img/arrow-left.svg") !important;
  }

  .owl-next {
    right: 0;

    background-image: url("../img/arrow-right.svg") !important;
  }
}

.technologies__items-wrapper {
  position: relative;
}

.techonologies__item {
  margin: 0 auto;
  width: 100px;
  height: 190px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.technologies__item-content {
  display: none;

  animation-name: showTabContent;
  animation-duration: 0.15s;
  animation-delay: 0.2;
}

.technologies__item-content.active {
  display: block;
}

.technologies__item-img {
  margin: auto;
}

.technologies__item-name {
  width: 100%;
  margin: 0;

  text-align: center;
  font-size: 16px;
  color: $second-color;
  font-weight: 500;
}

@keyframes showTabContent {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@media (max-width: $mobile-width-only) {
  .technologies {
    padding: 30px 0;
  }
}
