.cooperation-models {
  padding: 70px 0;
}

.cooperation-models__container {
  max-width: 945px;
}

.cooperation-models__list {
  margin: 0 0 65px;
  padding: 0;

  display: grid;
  grid-template-columns: 262px 300px 262px;
  grid-gap: 44px;
}

.cooperation-models__item {
  position: relative;

  min-height: 370px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  border: 1px solid rgba($third-color, 0.25);
}

.cooperation-models__item-stick {
  position: absolute;
  top: 20px;
  right: 18px;

  font-size: 10px;
  text-transform: uppercase;
  color: #35c5ff;
}

.cooperation-models__name {
  margin: 0 0 12px;
  
  font-size: 18px;
  text-align: center;
  font-weight: 900;
}

.cooperation-models__note {
  margin: 0 0 20px;

  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.cooperation-models__desc {
  margin: 0 0 24px;

  font-size: 15px;
  line-height: 24px;
}

.cooperation-models__link {
  margin: auto auto 0;
}

.cooperation-models__helper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 48px;

  box-shadow: 0 5px 5px rgba(32, 83, 207, 0.078);
}

.cooperation-models__logo-wrapper {
  width: 170px;
}

.cooperation-models__logo {
  width: 106px;
  height: 33px;
}

.cooperation-models__intro-question {
  font-size: 16px;
  font-weight: 700;
}

.cooperation-models__message-link {
  width: 170px;

  font-size: 16px;
  color: $second-color;

  &:hover,
  &:focus {
    border-bottom: 1px solid $second-color;
  }

  &:active {
    opacity: 0.7;
  }
}

@media (max-width: 970px) {
  .cooperation-models__list {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }

  .cooperation-models__item {
    min-height: 250px;
  }
}

@media (max-width: 850px) {
  .cooperation-models__helper {
    flex-direction: column;
  }

  .cooperation-models__item--popular {
    order: -1;
  }

  .cooperation-models__intro-question {
    order: -1;
    margin-bottom: 25px;
  }

  .cooperation-models__logo-wrapper {
    width: auto;
    margin-bottom: 20px;
    order: 2;
  }

  .cooperation-models__message-link {
    width: auto;
    margin-bottom: 15px;

    span {
      display: none;
    }
  }

}

@media (max-width: $mobile-width-only) {
  .cooperation-models {
    padding: 50px 0 50px;
  }
}
