.language-toggler {
  margin: 0;
  padding: 0;

  display: flex;

  list-style: none;
}

.language-toggler__item:not(:last-child) {
  padding-right: 13px;
  margin-right: 13px;

  font-size: 18px;

  border-right: 1px solid #555860;
}

.language-toggler__language-link {
  color: #fff;

  &:hover,
  &:focus {
    color: $second-color;
  }
}

.language-toggler__item--active {
  .language-toggler__language-link {
    color: $second-color;
  }
}

@media (max-width: $tablet-width-only) {
  .language-toggler {
    color: #fff;
  }

  .language-toggler__item--active .language-toggler__language-link {
    color: #fff;
  }

  .language-toggler__word {
    margin-right: 20px;
  }

  .language-toggler__item {
    text-transform: uppercase;
  }

  .language-toggler__item:not(:last-child) {
    padding-right: 13px;
    margin-right: 13px;
    border-right: 1px solid rgba(#fff, 0.22);
  }

  .language-toggler__item--active .language-toggler__language-link {
    border-bottom: 1px solid #fff;
  }
  
  .language-toggler__language-link {
    &:hover,
    &:focus {
      color: #fff;
      
      border-bottom: 1px solid #fff;
    }
  }
}