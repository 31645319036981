.advantages {
  padding: 70px 0;
}

.advantages__section-head {
  margin: 0 0 30px;
}

.advantages__list {
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  list-style: none;
}

.advantages__item {
  position: relative;

  width: 275px;
  margin-bottom: 60px;
  padding-top: 65px;
}

.advantages__item-icon-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  
  display: block;
}

.advantages__item-name {
  margin: 0 0 16px;
}

.advantages__item-desc {
  margin: 0;

  font-size: 16px;
}

@media (max-width: $mobile-width-only) {
  .advantages {
    padding: 50px 0 50px;
  }

  .advantages__list {
    flex-direction: column;
  }

  .advantages__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: unset;
    margin-bottom: 30px;
  }

  .advantages__item-desc {
    text-align: center;
  }

  .advantages__item-icon-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }
}
