.partnerships {
  padding: 50px 0 120px;
}

.partnerships__list {
  margin: 0 auto;
  padding: 0;

  width: 690px;
  display: flex;
  justify-content: space-between;

  list-style: none;
}

.partnerships__item {
  width: 160px;
  height: 75px;
  //background-color: $second-color;
  display: flex;
  overflow: hidden;
}

.partnerships__item img {
  height: 100%;
  margin: auto;
}

@media (max-width: $tablet-width-only) {
  .partnerships__list {
    width: 100%;
    justify-content: space-evenly;
  }
}

@media (max-width: $mobile-width-only) {
  .partnerships {
    padding: 50px 0 50px;
  }

  .partnerships__list {
    flex-direction: column;
    align-items: center;
  }

  .partnerships__item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
