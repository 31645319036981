.main-menu {
  margin: 0 20px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border-right: 1px solid #707070;

  list-style: none;
}

.main-menu__item {
  margin-right: 20px;

  font-size: 18px;
  font-weight: 500;
  color: $second-color;
}

.main-menu__item--active {
  .main-menu__link {
    border-bottom: 1px solid $second-color;
  }
}

.main-menu__link {
  color: $second-color;

  &:hover,
  &:focus {
    border-bottom: 1px solid $second-color;
  }

  &:active {
    opacity: 0.8;
  }
}

@media (max-width: $tablet-width-only) {
  .main-menu {
    display: block;
    margin: 0;
  }

  .main-menu__item {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  .main-menu__item:last-child .main-menu__link {
    border-bottom: 0;
  }

  .main-menu__link {
    flex-grow: 1;
    margin: 0;
    padding: 20px 55px;

    color: #FFFFFF;
    font-size: 20px;

    border-bottom: 1px solid rgba(#fff, 0.22);

    &:hover,
    &:focus {
      background-color: rgba(#fff, 0.22);
    }
  }

  .main-menu__item--active {
    .main-menu__link {
      border-bottom: 1px solid rgba(#fff, 0.22);
    }
  }
}
