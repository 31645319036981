.page-header__main-nav {
  position: relative;

  display: grid;
  grid-template-columns: 131px 1fr;
  justify-content: space-between;
  align-items: center;
}

.page-header__logo-link {
  display: block;
  margin: 20px auto 20px 0;

  text-decoration: none;
}

.page-header__logo-icon {
  width: 131px;
  height: 40px;
}

.page-header__logo-icon--mobile {
  display: none;
}

.page-header__menu-btn {
  display: none;
}

.page-header__contacts {
  display: flex;
  align-items: center;
}

.page-header__phone-number {
  margin-right: 40px;

  font-size: 15px;
  font-weight: 500;
  color: $third-color;
}

.page-header__menu-wrapper {
  display: flex;
  justify-content: flex-end;
}

.page-header__language-toggler {
  display: none;
}

@media (max-width: $tablet-width-only) {
  .page-header {
    width: 100%;

    background-color: $fourth-color;
  }

  .page-header__container {
    padding: 0;
  }

  .page-header__menu-wrapper {
    position: fixed;
    top: 80px;
    z-index: 2;

    display: none;
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;

    background-color: $fourth-color;
  }

  .page-header__main-wrapper {
    position: fixed;
    top: 0;
    z-index: 2;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    background-color: $fourth-color;
  }

  .page-header__logo-link {
    margin-left: 55px;
  }

  .page-header__logo-icon--desktop {
    display: none;
  }

  .page-header__logo-icon--mobile {
    display: block;
  }

  .page-header__menu-btn {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32px;
    height: 25px;
    padding: 0;
    margin-right: 40px;

    background: none;
    background-color: transparent;
    border: none;

    cursor: pointer;
  }

  .page-header__menu-btn-line {
    width: 100%;
    height: 3px;

    background-color: #fff;
  }

  .page-header__contacts {
    padding: 20px 35px;
  }
  
  .page-header__contacts {
    display: none;
  }

  .page-header__language-toggler {
    display: flex;
    width: 100%;
    margin-top: auto;
    padding: 20px 55px 45px;
  }

  
}

@media (max-width: $mobile-width-only) {
  
}
