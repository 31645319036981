.sub-menu {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;

  display: none;

  font-weight: 400;

  background-color: #FAFAFA;
  box-shadow: 5px 5px 10px 0 #00000014;
}

.sub-menu__wrapper {
  display: flex;
}

.sub-menu__intro {
  width: 480px;
  padding: 80px 70px;
}

.sub-menu__intro-word {
  display: block;
  margin-bottom: 25px;

  color: $main-color;
  font-size: 26px;
}

.sub-menu__intro-phrase {
  margin: 0;
  
  color: $third-color;
  font-size: 16px;
}

.sub-menu__list {
  margin: 0;
  padding: 64px 55px 64px 50px;

  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 55px;
  grid-row-gap: 20px;

  list-style: none;
}

.sub-menu__link {
  color: $main-color;

  &:hover,
  &:focus {
    color: $second-color;
    border-bottom: 1px solid $second-color;
  }

  &:active {
    opacity: 0.8;
  }
}

@media (max-width: $tablet-width-only) {
  .sub-menu {
    position: static;

    width: 100%;

    background-color: rgba(#fff, 0.13);
    box-shadow: none;
  }

  .sub-menu__intro {
    display: none;
  }

  .sub-menu__list {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    grid-column-gap: 0;
    width: 100%;
    padding: 0;
  }

  .sub-menu__link {
    display: block;
    padding: 18px 56px;

    color: #fff;

    &:hover,
    &:focus {
      color: #fff;

      background-color: rgba(#fff, 0.2);
      border-bottom: 0;
    }
  }
}
