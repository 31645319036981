.popup-full, .popup-call {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00b6ff7d;
  z-index: 9999;
  display: none;
}

.popup-full .wrap, .popup-call .wrap {
  display: flex;
}

.popup-full .wrap .inner, .popup-call .wrap .inner {
  width: 100%;
  max-width: 600px;
  min-width: 320px;
  margin: 5% auto;
  background: #FFFFFF;
  padding: 24px 84px;
  position: relative;
}

.popup-full .wrap .inner .contact-us__form, .popup-call .wrap .inner .contact-us__form{
  width: 100%;
}

.popup-full .wrap .inner .sub-text, .popup-full .wrap .inner h4, .popup-call .wrap .inner .sub-text, .popup-call .wrap .inner h4 {
  text-align: center;
}

.popup-full .wrap .inner form .button--filled, .popup-call .wrap .inner form .button--filled{
  margin: 0 auto;
}

.popup-full .wrap .inner .close, .popup-call .wrap .inner .close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.popup-full .wrap .inner .close img, .popup-call .wrap .inner .close img {
  cursor: pointer;
}

.popup-full .success-text, .popup-call .success-text, .contact-us__container .success-text {
  display: none;
}

@media (max-width: $mobile-width-only) {
  .popup-full .wrap .inner, .popup-call .wrap .inner {
    padding: 24px;
  }

  .popup-full .wrap .inner form .button--filled, .popup-call .wrap .inner form .button--filled{
    margin: 0;
    width: 100%;
  }
}
