.stages__steps {
  display: none;
}

@media (max-width: 1000px) {
  .stages__steps-img {
    display: none;
  }

  .stages__steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 600;

    span {
      display: inline-block;
      margin-bottom: 10px;

      border-bottom: 1px solid $fourth-color;
    }
  }

  .stages__step-container {
    position: relative;
    
    padding-bottom: 100px;

    &::after {
      content: "";

      position: absolute;
      bottom: 25px;
      left: 40px;

      height: 50px;
      width: 2px;

      background-color: $fourth-color;
    }

    &:last-of-type {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}