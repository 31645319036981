.input {
  padding: 19px 24px;

  font-size: 15px;

  border-radius: 5px;
  border: 1px solid #979696;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $second-color;
  }
}

