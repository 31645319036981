.networks {
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;

  list-style: none;
}

.networks__item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.networks__link {
  display: block;
  width: 32px;
  height: 32px;

  color: #fff;
  text-decoration: none;

  border-radius: 50%;
}

.networks__icon--focused {
  display: none;
}

.networks__link:focus,
.networks__link:hover {
  .networks__icon--unfocused {
    display: none;
  }

  .networks__icon--focused {
    display: block;
  }
}
