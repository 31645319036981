.contact-us {
  position: relative;

  padding: 110px 0 120px;

  background-image: url("../img/map-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contact-us__container {
  display: flex;
  justify-content: space-between;
  width: 975px;
}

.contact-us__head-col {
  width: 420px;
}

.contact-us__section-head {
  margin: 0 0 30px;

  text-align: left;
}

.contact-us__section-posthead {
  width: auto;

  text-align: left;
}

.contact-us__form {
  width: 440px;
}

.contact-us__input {
  width: 100%;
  margin-bottom: 28px;
}

.contact-us__textarea {
  width: 100%;
  margin-bottom: 40px;

  resize: none;
}

.contact-us__about-protection {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 20px;

  text-align: center;
  font-size: 14px;

  background-color: rgba(#283859CC, 0.08);

  a {
    color: $second-color;
    font-weight: 600;

    border-bottom: 1px solid $second-color;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.2;
    }
  }
}

.contact-us__form-bottom-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-us__checkbox-label {
  display: flex;
  align-items: center;
}

.contact-us__checkbox-label-text {
  font-size: 12px;
}

.contact-us__pseudo-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 10px;

  border-radius: 1px;
  border: 1px solid #D8D8D8;
  background-color: #fff;
}

.contact-us__hidden-checkbox:checked + .contact-us__pseudo-checkbox {
  background-color: $second-color;
  background-image: url("../img/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid $second-color;
  min-width: 15px;
}

@media (max-width: 1040px) {
  .contact-us__container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .contact-us__head-col {
    width: 100%;
    max-width: 500px;
  }

  .contact-us__section-head {
    text-align: center;
  }

  .contact-us__section-posthead {
    text-align: center;
  }
}

@media (max-width: 630px) {
  .contact-us__form {
    width: 100%;
  }

  .contact-us__checkbox-label {
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .technologies__headers {
    display: flex;
    flex-direction: column;
  }

  .technologies__headers .technologies__header {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 9px;
  }

  .technologies__headers .technologies__header:last-child {
    margin-bottom: 0;
  }

  .networks {
    flex-wrap: nowrap;
  }

  .networks .networks__item {
    margin-right: 15px;
  }

  .page-footer__middle-block {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  a.page-footer__link {
    margin-right: 0!important;
  }
}

@media (max-width: $mobile-width-only) {
  .contact-us__input {
    margin-bottom: 15px;
  }

  .contact-us__textarea {
    margin-bottom: 30px;
  }
}
