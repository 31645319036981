.development-services__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4px;
  padding: 0;
  margin: 0;

  list-style: none;
}

.development-services__item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 65px;
  padding: 10px;

  text-align: center;

  background-color: #f9f9fb;

  span {
    max-width: 270px;
  }
}

@media (max-width: $tablet-width-only) {
  .development-services__list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: $mobile-width-only) {
  .development-services__list {
    grid-template-columns: 1fr;
  }
}
