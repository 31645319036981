.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;

  border-radius: 5px;
  border: 1px solid $second-color;

  &:active {
    opacity: 0.85;
  }
}

.button--empty {
  padding: 9px 17px;

  color: $second-color;
  font-weight: 500;

  background-color: transparent;

  &:hover,
  &:focus {
    background-color: rgba($second-color, 0.05);
  }
}

.button--filled {
  padding: 12px 35px;

  color: #fff;

  background-color: $second-color;

  &:hover,
  &:focus {
    background-color: darken($second-color, 20%);
    border: 1px solid darken($second-color, 20%);
  }
}
