.outsourcing__list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  list-style: none;

}

.outsourcing__item {
  position: relative;

  width: 290px;
  padding-left: 80px;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &:nth-of-type(1)::before {
    width: 48.04px;
    height: 48px;

    background-image: url("../img/collect-icon.svg");
  }

  &:nth-of-type(2) {
    width: 390px;
    margin: 0 auto;

    &::before {
      width: 43px;
      height: 43px;

      background-image: url("../img/locator-icon.svg");
    }
  }

  &:nth-of-type(3)::before {
    width: 51.92px;
    height: 41.2px;

    background-image: url("../img/sort-icon.svg");
  }
}

.outsourcing__icon {
  position: absolute;
  top: 0%;
  left: 0;

  // Временно, до настройки иконок.
  display: none;
}

@media (max-width: 1150px) {
  .outsourcing__list {
    display: grid;
    justify-content: center;
    grid-template-columns: 400px;
    grid-row-gap: 30px;
  }
  
  .outsourcing__item {
    width: auto;

    &:nth-of-type(2) {
      width: auto
    }
  }
}

@media (max-width: 550px) {
  .outsourcing__list {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(50px, max-content);
  }
}
