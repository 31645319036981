.many-technologies {
  padding: 120px 0 65px;
}

.many-technologies__container {
  width: 1015px;
}

.many-technologies__list {
  margin: 0;
  padding: 0;

  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, 285px);
  grid-row-gap: 50px;

  list-style: none;
}

.many-technologies__item {
  position: relative;

  padding-top: 65px;
}

.many-technologies__item-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.many-technologies__pile {
  margin: 0;
}

@media (max-width: $tablet-width-only) {
  .many-technologies__container {
    width: 100%;
  }

  .many-technologies__list {
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 285px);
  }
}
