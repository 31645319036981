.solutions__top-block {
  max-width: 1090px;
  padding: 0 30px;
  width: 100%;
}

.solution__name-block {
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  min-height: 240px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.solution__name-block--cloud {
  background-image: url("../img/clouds.png");
}

.solution__name-block--fasie {
  background-image: url("../img/fasie.png");
}

.solution__name-block--blockchain {
  background-image: url("../img/hand-down.png");
}

.solution__name-block--iot {
  background-image: url("../img/interior.png");
}

.solution__name-block--messenger {
  background-image: url("../img/man-with-phone.png");
}

.solution__content-block {
  padding: 80px 0 0;
}

.solution__inner-container {
  padding: 0 130px;
}

.solution__name {
  margin: 0;

  font-size: 38px;
}

.solution__text {
  margin: 0 0 40px;

  line-height: 29px;
}

.solution__item-details {
  margin-top: 45px;
}

.solution__item-detail {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.solution__bottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}

.solution__other-tag {
  min-height: 36px;
  padding: 7px 24px;
  margin-right: 25px;

  border-radius: 5px;
  background-color: rgba($third-color, 0.1);

  &:last-child {
    margin-right: 0;
  }
}

.solution__bottom-link-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.solution__bottom-link {
  margin-right: 17px;

  color: $second-color;
  font-weight: 700;

  &:focus,
  &:link {
    border-bottom: 1px solid $second-color;
  }
}

.solution__item-details-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

@media (max-width: $mobile-width-only) {
  .solution__name-block {
    padding-bottom: 0;
    min-height: 50px;

    background-size: contain;
    background-position: center;

    .solution__name {
      font-size: 17px;
    }

    .solution__text, .solution__item-detail {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .solution__content-block {
    padding: 0;
    padding-top: 10px;
  }

  .solution__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .solution__inner-container  {
    padding: 0;
  }

  .solution__bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .solution__bottom-link-wrapper  {
    margin-top: 25px;
  }

  .solutions__solution {
    padding: 20px 0;
  }

  .solution__tags {
    .solution__other-tag {
      margin-right: 0;
    }
  }
}
