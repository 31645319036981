.approach {
  padding: 60px 0;
  overflow: hidden;
}

.approach__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.approach__text-block {
  padding: 65px 45px 0 0;
}

.approach__section-head {
  text-align: left;
}

.approach__section-posthead {
  margin: 0;

  line-height: 29px;
}

.approach__content {
  position: relative;

  padding: 68px 56px;

  background-color: #F9F9FB;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: -100%;

    width: 100%;
    height: 100%;

    background-color: #f9f9fb;
  }
}

.approach__text {
  margin: 0 0 50px;
  
  font-size: 16px;
  line-height: 24px;
}

.approach__instruments {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 65px;
  grid-row-gap: 25px;
  
  list-style: none;
}

.approach__instrument {
  color: $second-color;
  font-weight: 700;
}

@media (max-width: $tablet-width-only) {
  .approach__container {
    display: block;
  }

  .approach__text-block {
    padding: 0;
    margin-bottom: 30px;
  }

  .approach__section-head {
    text-align: center;
  }

  .approach__section-posthead {
    text-align: center;
  }

  .approach__content {
    padding: 0;

    &::after {
      display: none;
    }
  }
}

@media (max-width: $mobile-width-only) {
  .approach__text {
    text-align: center;
  }

  .approach__instruments {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }
}
