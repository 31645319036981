.specializations {
  padding: 120px 0;

  background-image: url("../img/fan-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.specializations__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.specializations__section-head {
  width: 280px;

  text-align: left;
}

.specializations__list {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 100px;
  grid-row-gap: 15px;

  list-style: none;
}

.specializations__item {
  color: $second-color;
  font-weight: 700;

  span {
    color: $third-color;
    font-weight: 500;
  }

  &--1-2 {
    grid-column: 1/2;
  }
}


@media (max-width: $tablet-width-only) {
  .specializations__section-head {
    margin: 0 auto 30px;

    text-align: center;
  }

  .specializations__list {
    margin: 0 auto;
  }
}

@media (max-width: $mobile-width-only) {
  .specializations__list {
    margin: 0 auto;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }

  .specializations__item {
    text-align: center;
  }
}