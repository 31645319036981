.solutions__section-head {
  font-size: 40px;
}

.solutions__section-posthead {
  max-width: 890px;
}

@media (max-width: $tablet-width-only) {
  .solutions__section-head {
    font-size: 30px;
  }
}

@media (max-width: $mobile-width-only) {
  .solutions__top-block {
    padding: 0;
  }
  
  .solutions__section-head {
    font-size: 25px;
  }
}
