.request-consultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0 80px;

  font-weight: 500;

  background-image: url("../img/glass-windows.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.request-consultation__desc {
  width: 830px;
  margin: 0 auto 30px;

  text-align: center;
}

@media (max-width: $tablet-width-only) {
  .request-consultation__desc {
    max-width: 830px;
    width: 100%;
    padding: 0 10px;
  }
}

@media (max-width: $mobile-width-only) {
  .request-consultation {
    padding: 30px 0;
  }
}
