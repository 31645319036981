.some-phrase {
  padding: 80px 0;
  margin: 0 auto;
  width: 775px;

  font-size: 18px;
  line-height: 29px;
}

@media (max-width: $tablet-width-only) {
  .some-phrase {
    width: 100%;
    padding: 50px 15px;
  }
}
