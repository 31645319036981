.title-section {
  padding: 30px;
  margin: 0 auto;
  max-width: 1365px;
  min-height: 510px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.title-section__text-block {
  width: 630px;
}

.title-section__section-head {
  margin: 0 0 35px;

  text-align: left;
  font-size: 40px;
}

.title-section__section-posthead {
  width: auto;
  margin: 0 0 40px;

  text-align: left;
  line-height: 29px;
}

.title-section__buttons {
  display: flex;
  flex-wrap: wrap;
}

.title-section__link {
  margin-right: 55px;

  &:nth-of-type(2) {
    width: 168px;
  }
}

@media (max-width: $mobile-width-only) {
  .title-section {
    padding: 20px 0;
  }

  .title-section__text-block {
    width: 100%;
  }

  .title-section__section-head {
    font-size: 25px;
  }

  .title-section__section-posthead {
    font-size: 16px;
  }

  .title-section__link {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
