*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;

  display: flex;
  flex-direction: column;

  min-width: 320px;
  margin: 0;
  padding: 0;

  font-family: $main-font;
  font-size: 18px;
  line-height: 1.25;
  font-weight: normal;
  font-style: normal;
  color: #000;

  background-color: #fff;
}

img {
  display: block;

  max-width: 100%;
}

a {
  text-decoration: none;
}

@media (max-width: $mobile-width-only) {
  body {
    font-size: 16px;
  }
}