.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: $tablet-width) {
  .container {
    width: 100%;
  }
}

@media (min-width: $desktop-width) {
  .container {
    width: 1235px;
  }
}
