.expertise {
  padding: 100px 0 120px;
}

.expertise__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.expertise__text-block {
  width: 510px;
}

.expertise__section-head {
  text-align: left;
}

.expertise__section-posthead {
  width: auto;

  text-align: left;
}

.expertise__list {
  width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  margin: 0;
  padding: 0;

  list-style: none;
}

.expertise__item {
  color: $third-color;
  font-weight: 700;
}

@media (max-width: $tablet-width-only) {
  .expertise__container {
    flex-direction: column;
  }

  .expertise__text-block {
    width: 100%;
  }

  .expertise__section-head {
    text-align: center;

    br {
      display: none;
    }
  }

  .expertise__list {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: $mobile-width-only) {
  .expertise__list {
    grid-template-columns: repeat(auto-fill, minmax(280px, min-content));
    justify-content: center;
  }

  .expertise__section-posthead {
    text-align: center;
  }
}

@media (max-width: 610px) {

  .expertise__list {
    justify-items: center;
  }
}